<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('shiftManagement.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('shiftManagement.pageTitle'),
                        href: '/admin/shift-managements'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="row">
                        <div class="col-md-12 tw-pt-5">
                            <shift
                                v-model="model"
                                :validate="errors"
                                class="tw-h-full"
                            />
                        </div>
                        <div class="col-md-12 tw-pb-5">
                            <shift-working-hour
                                v-model="model"
                                :validate="errors"
                                class="tw-h-full"
                            />
                        </div>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
        </vue-custom-scrollbar>
        <Spin size="large" fix v-if="loading"> </Spin>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'shift-management' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting_new || waiting"
                @click.prevent="onSaveAddNew"
            >
                {{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new || waiting"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>
<script>
import AppOptions from "@/config/AppOptions.vue";
import { Errors } from "form-backend-validation";
import Shift from "./components/shift.vue";
import ShiftWorkingHour from "./components/shift-working-hour.vue";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "shiftManagementCreate",
    components: { Shift, ShiftWorkingHour },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                shift_name: null,
                shift_type: null,
                minute_allow: null,
                details: []
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    computed: {
        ...mapState("humanResource/shiftManagement", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/shiftManagement/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/shiftManagement/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$router.push({ name: "shift-management" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.model.shift_name = null;
            this.model.shift_type = null;
            this.model.minute_allow = null;
            this.model.details = [];
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "SHIFT MANAGEMENT",
                desc: not.text
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
